export const topLevelDomains = [
    "com",
    "net",
    "org",
    "cc",
    "info",
    "biz",
    "ag",
    "ai",
    "am",
    "at",
    "be",
    "bz",
    "ca",
    "ch",
    "cn",
    "co",
    "cz",
    "de",
    "es",
    "fm",
    "gg",
    "gs",
    "in",
    "io",
    "la",
    "ms",
    "mx",
    "nl",
    "pe",
    "ph",
    "pl",
    "sg",
    "tc",
    "tk",
    "tv",
    "tw",
    "us",
    "vc",
    "ws",
    "co.uk",
    "me.uk",
    "org.uk",
    "pro",
    "eu",
    "xxx",
    "mobi",
    "asia",
    "me",
    "tel",
    "uno",
    "menu",
    "luxury",
    "build",
    "bike",
    "clothing",
    "guru",
    "holdings",
    "plumbing",
    "singles",
    "ventures",
    "estate",
    "photography",
    "gallery",
    "camera",
    "lighting",
    "equipment",
    "graphics",
    "kitchen",
    "construction",
    "contractors",
    "directory",
    "today",
    "technology",
    "land",
    "tips",
    "diamonds",
    "enterprises",
    "voyage",
    "careers",
    "recipes",
    "photos",
    "shoes",
    "company",
    "cab",
    "domains",
    "limo",
    "management",
    "systems",
    "center",
    "computer",
    "support",
    "academy",
    "solutions",
    "repair",
    "house",
    "builders",
    "camp",
    "education",
    "glass",
    "international",
    "solar",
    "training",
    "email",
    "institute",
    "florist",
    "coffee",
    "club",
    "codes",
    "farm",
    "holiday",
    "marketing",
    "agency",
    "cheap",
    "zone",
    "bargains",
    "boutique",
    "cool",
    "watch",
    "viajes",
    "expert",
    "works",
    "reviews",
    "dance",
    "democrat",
    "futbol",
    "foundation",
    "cruises",
    "rentals",
    "villas",
    "exposed",
    "flights",
    "vacations",
    "social",
    "immobilien",
    "ninja",
    "consulting",
    "rocks",
    "republican",
    "buzz",
    "maison",
    "properties",
    "tienda",
    "condos",
    "dating",
    "events",
    "productions",
    "partners",
    "london",
    "cards",
    "cleaning",
    "catering",
    "community",
    "wiki",
    "parts",
    "supply",
    "industries",
    "supplies",
    "tools",
    "xyz",
    "ink",
    "bid",
    "report",
    "vision",
    "moda",
    "pub",
    "trade",
    "webcam",
    "fish",
    "actor",
    "kaufen",
    "services",
    "rest",
    "bar",
    "engineering",
    "gripe",
    "capital",
    "exchange",
    "lease",
    "pictures",
    "media",
    "associates",
    "reisen",
    "university",
    "toys",
    "town",
    "haus",
    "financial",
    "wtf",
    "fail",
    "limited",
    "care",
    "clinic",
    "surgery",
    "dental",
    "nyc",
    "cash",
    "tax",
    "fund",
    "investments",
    "vegas",
    "tokyo",
    "furniture",
    "discount",
    "fitness",
    "schule",
    "nagoya",
    "world",
    "charity",
    "shopping",
    "college",
    "skin",
    "studio",
    "movie",
    "kiwi",
    "ltda",
    "degree",
    "vote",
    "army",
    "church",
    "law",
    "digital",
    "legal",
    "blue",
    "place",
    "gives",
    "vip",
    "porn",
    "fit",
    "archi",
    "loans",
    "llc",
    "fun",
    "healthcare",
    "vin",
    "bayern",
    "blackfriday",
    "tech",
    "site",
    "review",
    "style",
    "money",
    "monster",
    "country",
    "guide",
    "yachts",
    "page",
    "horse",
    "blog",
    "ist",
    "accountant",
    "family",
    "website",
    "hiv",
    "apartments",
    "sex",
    "red",
    "cooking",
    "ski",
    "surf",
    "tattoo",
    "work",
    "sarl",
    "green",
    "school",
    "tennis",
    "voto",
    "racing",
    "rich",
    "golf",
    "promo",
    "rehab",
    "lawyer",
    "gratis",
    "hair",
    "vodka",
    "poker",
    "fan",
    "pics",
    "business",
    "sydney",
    "cricket",
    "theatre",
    "flowers",
    "vet",
    "energy",
    "win",
    "attorney",
    "lol",
    "yoga",
    "loan",
    "xn--6frz82g",
    "dev",
    "fans",
    "hockey",
    "space",
    "science",
    "gold",
    "global",
    "tickets",
    "onl",
    "news",
    "wine",
    "fishing",
    "earth",
    "bingo",
    "ceo",
    "miami",
    "store",
    "amsterdam",
    "paris",
    "qpon",
    "beer",
    "faith",
    "inc",
    "lat",
    "app",
    "doctor",
    "quest",
    "engineer",
    "makeup",
    "team",
    "theater",
    "film",
    "taxi",
    "hiphop",
    "lgbt",
    "ong",
    "irish",
    "fyi",
    "health",
    "juegos",
    "protection",
    "shiksha",
    "gifts",
    "restaurant",
    "date",
    "tube",
    "rodeo",
    "ngo",
    "cafe",
    "stream",
    "rip",
    "adult",
    "chat",
    "kids",
    "men",
    "link",
    "garden",
    "istanbul",
    "casino",
    "delivery",
    "guitars",
    "download",
    "gift",
    "network",
    "group",
    "moe",
    "design",
    "online",
    "navy",
    "motorcycles",
    "salon",
    "click",
    "one",
    "auto",
    "party",
    "casa",
    "okinawa",
    "city",
    "wedding",
    "hospital",
    "car",
    "cars",
    "boats",
    "software",
    "sale",
    "dog",
    "black",
    "video",
    "ryukyu",
    "bet",
    "memorial",
    "property",
    "jewelry",
    "credit",
    "courses",
    "band",
    "games",
    "airforce",
    "storage",
    "art",
    "yokohama",
    "diet",
    "help",
    "sexy",
    "finance",
    "autos",
    "show",
    "pink",
    "football",
    "direct",
    "mom",
    "coach",
    "jetzt",
    "love",
    "immo",
    "audio",
    "game",
    "boston",
    "cloud",
    "ltd",
    "security",
    "mba",
    "insure",
    "life",
    "market",
    "accountants",
    "tires",
    "contact",
    "live",
    "tours",
    "dentist",
    "bio",
    "reise",
    "deals",
    "auction",
    "pet",
    "christmas",
    "host",
    "fashion",
    "realestate",
    "coupons",
    "homes",
    "baby",
    "creditcard",
    "beauty",
    "mortgage",
    "express",
    "shop",
    "kim",
    "forsale",
    "plus",
    "photo",
    "hosting",
    "soccer",
    "study",
    "melbourne",
    "pizza",
    "claims",
    "gmbh",
    "best",
    "rent",
    "press",
    "run"
];