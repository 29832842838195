

































import { DomainAvailability, DomainsService } from '@/api/braendz';
import { BusyList } from '@/models/Busy';
import { topLevelDomains } from '@/models/Domains';
import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component({
  components: {
  }
})
export default class DomainTest extends Vue {

  public domainname = "";

  public domainCheckResult = new BusyList<DomainAvailability>();
  public tlds: string[] | null = null;

  public headers = [
        { text: 'Domain', align: 'start', value: 'domain', groupable: false },
        { text: 'Available', align: 'start', value: 'available', groupable: true },
        { text: 'Definitive', align: 'start', value: 'definitive', groupable: false },
    ] as DataTableHeader[];

  public async searchAll() {
    
    await this.domainCheckResult.load(async () => {
      const domains = topLevelDomains.map(tld => `${this.domainname}.${tld}`);
      return await DomainsService.checkDomainAvailabilities(domains);
    });
  }

}
